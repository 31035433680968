import { NavLink } from 'react-router-dom';
import s from './s.module.css';

export function Footer() {
  return (
    <footer className={s.footer}>
      <nav className={s.nav}>
        <NavLink to="/privacypolicy" className={s.item}>
          Privacy Policy
        </NavLink>
        <NavLink to="/cookiepolicy" className={s.item}>
          Cookie Policy
        </NavLink>
        <span className={s.item}>
          Contact: <span className={s.contact}>contact@filrep.io</span>
        </span>
      </nav>
      <span className={s.item}>Powered by DigitalMOB</span>
    </footer>
  );
}
