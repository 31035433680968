import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import reportWebVitals from './reportWebVitals';
import { GeneralProvider } from 'context/general';
import App from './components/App';

import './index.css';
import './table.css';
import './button.css';

// polyfill
import 'wicg-inert';

ReactDOM.render(
  <React.StrictMode>
    <GeneralProvider>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <App />
        </QueryParamProvider>
      </Router>
    </GeneralProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

document.body.addEventListener('mousedown', () => {
  document.body.classList.add('using-mouse');
});

document.body.addEventListener('keydown', (event) => {
  if (event.key === 'Tab') {
    document.body.classList.remove('using-mouse');
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`);
