export const defaultSort = { sortBy: 'score', order: 'desc' };

export const columnsStorageName = 'shown-columns';
export const versionStorageName = 'columns-version';
export const columnsVersion = '15';
export const limitDefault = 10;
export const tokenStorageName = 'auth-token';

export const privacyStorageName = 'privacy-accepted';

export const passwordMinLength = 8;
export const emailRegExp = new RegExp(
  '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$'
);

export const defaultColumns = [
  {
    name: 'Rank',
    filterName: 'Rank',
    key: 'rank',
    selected: true,
  },
  {
    name: 'Miner ID',
    filterName: 'Miner ID',
    key: 'address',
    selected: true,
  },
  {
    name: 'Tag',
    filterName: 'Tag',
    key: 'tag.name',
    selected: false,
  },
  {
    name: 'Country',
    filterName: 'Country',
    key: 'isoCode',
    selected: true,
  },
  {
    name: 'Region',
    filterName: 'Region',
    key: 'region',
    selected: true,
  },
  // {
  //   name: 'Uptime',
  //   filterName: 'Uptime',
  //   key: 'uptimeAverage',
  //   selected: true,
  //   alignRight: true,
  //   sortKey: 'uptime',
  // },
  {
    name: 'Online Reachability',
    filterName: 'Online Reachability',
    key: 'reachability',
    selected: true,
  },
  {
    name: 'Total Raw Power',
    filterName: 'Total Raw Power',
    key: 'rawPower',
    selected: true,
    alignRight: true,
    sortKey: 'rawPower',
  },
  {
    name: 'Total Quality Adjusted Power',
    filterName: 'Total Quality Adjusted Power',
    key: 'qualityAdjPower',
    selected: true,
    alignRight: true,
    sortKey: 'qualityAdjPower',
  },
  {
    name: 'Last Price For Verified Deals',
    filterName: 'Last price for verified deals',
    key: 'verifiedPrice',
    selected: true,
    alignRight: true,
  },
  {
    name: 'Last Price For Non Verified Deals',
    filterName: 'Last price for non verified deals',
    key: 'price',
    selected: true,
    alignRight: true,
  },
  {
    name: 'Min File Size',
    filterName: 'Min File Size',
    key: 'minPieceSize',
    selected: false,
    alignRight: true,
  },
  {
    name: 'Max File Size',
    filterName: 'Max File Size',
    key: 'maxPieceSize',
    selected: false,
    alignRight: true,
  },
  {
    name: 'Committed capacity',
    filterName: 'Committed capacity',
    key: 'freeSpace',
    selected: true,
    alignRight: true,
    sortKey: 'freeSpace',
  },
  {
    name: 'Recent storage deals',
    filterName: 'Recent storage deals',
    key: 'storageDeals.recent30days',
    selected: false,
    alignRight: true,
  },
  {
    name: 'Average storage deal price',
    filterName: 'Average storage deal price',
    key: 'storageDeals.averagePrice',
    selected: true,
    alignRight: true,
    sortKey: 'averageStorageDealsPrice',
  },
  {
    name: 'Total Storage Deals',
    filterName: 'Total Storage Deals',
    key: 'storageDeals.total',
    selected: false,
    alignRight: true,
  },
  {
    name: 'Storage Deals with no penalties',
    filterName: 'Storage Deals with no penalties',
    key: 'storageDeals.noPenalties',
    selected: false,
    alignRight: true,
    sortKey: 'noPenalties',
  },
  {
    name: 'Storage Deals success rates',
    filterName: 'Storage Deals success rates',
    key: 'storageDeals.successRate',
    selected: false,
    alignRight: true,
  },
  {
    name: 'No of Slashed contracts',
    filterName: 'No of Slashed contracts',
    key: 'storageDeals.slashed',
    selected: false,
    alignRight: true,
  },
  {
    name: 'Lifetime data stored',
    filterName: 'Lifetime data stored',
    key: 'storageDeals.dataStored',
    selected: false,
    alignRight: true,
    sortKey: 'dataStored',
  },
  {
    cuid: 'energy',
    name: 'Renewable Energy Purchased',
    filterName: 'Renewable Energy Purchased',
    key: 'energy',
    selected: false,
    alignRight: true,
    sortKey: 'energy',
  },
  {
    name: 'Credit Score',
    filterName: 'Credit Score',
    key: 'creditScore',
    selected: false,
  },
];
