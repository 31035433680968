import { useEffect, useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import s from './s.module.css';

import { privacyStorageName } from 'constant';

export const CookiePopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const policyAccepted = window.localStorage.getItem(privacyStorageName);

    if (policyAccepted === null) {
      setShowPopup(true);
    }
  }, []);

  if (!showPopup) return null;

  const handlerAccept = () => {
    window.localStorage.setItem(privacyStorageName, String(true));
    setShowPopup(false);
  };

  const handlerReject = () => {
    window.localStorage.setItem(privacyStorageName, String(false));
    setShowPopup(false);
  };

  return (
    <div className={s.wrap}>
      We use cookie to improve your experience on our site. To find out more,
      read out <Link to="/privacypolicy">Privacy policy</Link> and{' '}
      <Link to="/cookiepolicy">Cookie policy</Link>.
      <div className={s.buttonsWrap}>
        <button
          onClick={handlerAccept}
          type="button"
          className={cn(s.button, 'button button--primary')}
        >
          Accept
        </button>
        <button
          onClick={handlerReject}
          type="button"
          className={cn(s.button, 'button button--secondary')}
        >
          Reject
        </button>
      </div>
    </div>
  );
};
