import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { nanoid } from 'nanoid';

import { noop } from 'utils/noop';
import { generateErrorMessages } from 'utils/error';
import { Notification } from 'components/Notification';
import { tokenStorageName } from 'constant';

export const GeneralContext = React.createContext({
  api: (param) => Promise.resolve(param),
  generalSelectors: {
    user: null,
    isLogged: false,
  },
  generalActions: {
    setUser: noop,
    setToken: noop,
    logout: noop,
  },
  notificationActions: {
    addNotification: noop,
    removeNotification: noop,
    clearNotifications: noop,
  },
});

const notificationsNode = document.querySelector('#root-notifications');

export const GeneralProvider = ({ children }) => {
  const [user, setUser] = React.useState();
  const [notifications, setNotifications] = React.useState([]);

  const api = React.useCallback(
    async (
      url,
      { headers = {}, data, useToken, signal, ...restOptions } = {},
      processResponseMethod = 'json'
    ) => {
      const requestHeaders = Object.entries({
        'Content-Type': 'application/json',
        ...headers,
      }).reduce((a, [k, v]) => (v == null ? a : { ...a, [k]: v }), {});

      const token = localStorage.getItem(tokenStorageName);
      if (token && !requestHeaders.Authorization && useToken) {
        requestHeaders.Authorization = `Bearer ${token}`;
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}${url}`,
        {
          headers: new Headers(requestHeaders),
          mode: 'cors',
          ...restOptions,
        }
      );

      if (!response.ok) {
        throw await response.json();
      }

      return processResponseMethod ? response[processResponseMethod]() : true;
    },
    []
  );

  const setToken = React.useCallback((t) => {
    if (t) {
      window.localStorage.setItem(tokenStorageName, t);
    } else if (localStorage[tokenStorageName]) {
      window.localStorage.removeItem(tokenStorageName);
    }
  }, []);

  React.useEffect(() => {
    const token = window.localStorage.getItem(tokenStorageName);
    if (token) {
      api('/accounts/me', { useToken: true })
        .then((resp) => {
          setToken(token);
          setUser(resp.account);
        })
        .catch(() => {
          setUser(null);
        });
    } else {
      setUser(null);
    }
  }, []);

  const logoutHandler = () => {
    window.localStorage.removeItem(tokenStorageName);
    setUser(null);
  };

  const addNotification = React.useCallback(
    // level: info | success | warning | error
    (message, { level = 'error', timeout = 5000, override = false } = {}) => {
      const prettyMessage = generateErrorMessages(message);
      if (prettyMessage) {
        const nItem = { id: nanoid(), message: prettyMessage, level, timeout };

        setNotifications((ns) => [...(override ? [] : ns), nItem]);
      }
    },
    [setNotifications]
  );

  const removeNotification = React.useCallback(
    (id) => {
      setNotifications((prevNotifications) =>
        prevNotifications.filter((n) => n.id !== id)
      );
    },
    [setNotifications]
  );

  const clearNotifications = () => {
    setNotifications([]);
  };

  return (
    <GeneralContext.Provider
      value={{
        api,
        generalSelectors: {
          user,
          isLogged: !!user,
        },
        generalActions: {
          setUser,
          setToken,
          logout: logoutHandler,
        },
        notificationActions: {
          addNotification,
          removeNotification,
          clearNotifications,
        },
      }}
    >
      {children}
      {notificationsNode &&
        ReactDOM.createPortal(
          <>
            {notifications.map((n) => (
              <Notification key={n.id} n={n} onClose={removeNotification} />
            ))}
          </>,
          notificationsNode
        )}
    </GeneralContext.Provider>
  );
};

export const useGeneral = () => useContext(GeneralContext);
