export function generateErrorMessages(error) {
  if (Array.isArray(error?.message)) {
    return error.message.flatMap((em) =>
      em.constraints ? Object.values(em.constraints) : em
    );
  }

  if (error instanceof Error) {
    return [error.message];
  }

  if (typeof error?.message === 'string') {
    return [error?.message];
  }

  if (typeof error === 'string') {
    return [error];
  }

  return [];
}
