import { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { Spinner } from 'components/Spinner';

import { CookiePopup } from 'components/CookiePopup';

const HomePage = lazy(() => import('components/HomePage'));
const AccountPages = lazy(() => import('components/AccountPages'));
const ApiPage = lazy(() => import('components/ApiPage'));
const MethodologyPage = lazy(() => import('components/MethodologyPage'));
const LoginPage = lazy(() => import('components/AuthPages/LoginPage'));
const SignupPage = lazy(() => import('components/AuthPages/SignupPage'));
const RecoverPasswordPage = lazy(() =>
  import('components/AuthPages/RecoverPasswordPage')
);
const ConfirmEmailPage = lazy(() =>
  import('components/AuthPages/ConfirmEmailPage')
);
const ResetPasswordPage = lazy(() =>
  import('components/AuthPages/ResetPasswordPage')
);
const PrivacyPolicyPage = lazy(() => import('components/PrivacyPolicyPage'));
const CookiePolicyPage = lazy(() => import('components/CookiePolicyPage'));
const LogsPage = lazy(() => import('components/LogsPage'));

function App() {
  return (
    <>
      <Header />
      <main>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/login" component={LoginPage} />
            <Route path="/signup" component={SignupPage} />
            <Route path="/account" component={AccountPages} />
            <Route path="/recover-password" component={RecoverPasswordPage} />
            <Route path="/reset-password" component={ResetPasswordPage} />
            <Route path="/confirm-email" component={ConfirmEmailPage} />
            <Route path="/api" component={ApiPage} />
            <Route path="/methodology" component={MethodologyPage} />
            <Route path="/privacypolicy" component={PrivacyPolicyPage} />
            <Route path="/cookiepolicy" component={CookiePolicyPage} />
            <Route path="/logs/:minerId" component={LogsPage} />

            <Redirect to="/" />
          </Switch>
        </Suspense>
      </main>
      <Footer />

      <CookiePopup />
    </>
  );
}

export default App;
