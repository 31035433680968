import { NavLink, Link, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';

import { useGeneral } from 'context/general';
import { Svg } from 'components/Svg';
import s from './s.module.css';

export function Header() {
  const isAuthPages = useRouteMatch([
    '/login',
    '/signup',
    '/recover-password',
    '/reset-password',
    '/confirm-email',
  ]);
  const isAccountPages = useRouteMatch('/account*');
  const { generalSelectors, generalActions } = useGeneral();

  return (
    <header className={cn(s.header, { [s.minerPanel]: isAccountPages })}>
      <NavLink to="/" className={s.logoLink}>
        <Svg id="logo" width={32} height={32} />
        <span className={s.filrep}>FilRep</span>
      </NavLink>

      <nav className={s.nav}>
        <NavLink exact to="/" className={s.link} activeClassName={s.active}>
          Home
        </NavLink>
        <NavLink
          to="/methodology"
          className={s.link}
          activeClassName={s.active}
        >
          Methodology
        </NavLink>
        <NavLink to="/api" className={s.link} activeClassName={s.active}>
          API
        </NavLink>
      </nav>

      {generalSelectors.isLogged ? (
        <nav className={s.secondaryNav}>
          <NavLink to="/account" className={s.link} activeClassName={s.active}>
            My Account
          </NavLink>
          <Link to="/" onClick={generalActions.logout} className={s.link}>
            Log Out
          </Link>
        </nav>
      ) : (
        <>
          {!isAuthPages ? (
            <nav className={s.secondaryNav}>
              <Link
                to="/login"
                type="button"
                className={cn('button', 'button--primary', s.loginLink)}
              >
                Storage Provider Log In
              </Link>
            </nav>
          ) : null}
        </>
      )}
    </header>
  );
}
