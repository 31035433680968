import React from 'react';
import cn from 'classnames';

import { Svg } from 'components/Svg';
// eslint-disable-next-line css-modules/no-unused-class
import s from './s.module.css';

export const Notification = ({ n, onClose }) => {
  React.useEffect(() => {
    if (n.timeout && n.timeout !== Infinity) {
      setTimeout(onClose, n.timeout, n.id);
    }
  }, [n.timeout]);

  return (
    <div className={cn(s.notification, s[n.level])}>
      {n.message}
      <button
        className={s.close}
        onClick={() => onClose(n.id)}
        type="button"
        aria-label="Close"
      >
        <Svg id="close" />
      </button>
    </div>
  );
};
