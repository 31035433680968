import cn from 'classnames';

import { Svg } from 'components/Svg';
import s from './s.module.css';

export const Spinner = ({ className, width = 40, height = 40 }) => (
  <div className={cn(s.wrap, className)}>
    <Svg id="loading" width={width} height={height} className={s.icon} />
  </div>
);
